import type { SortingPanelFieldI } from "@/utils/getters/defaultFilters";

export const sortingPanelLots = (): SortingPanelFieldI[] => {
  return [
    { title: "lotType", label: "Тип" },
    { title: "lotLaw", label: "Закон" },
    { title: "dateResult", label: "Дата итогов" },
    { title: "participantsCount", label: "Участников" },
    { title: "cost", label: "Начальная цена", minWidth: "170px" },
    { title: "offeredPrice", label: "Предложенная цена", minWidth: "170px" },
  ];
};

export const sortingPanelCustomers = (): SortingPanelFieldI[] => {
  return [
    { title: "lotsCount", label: "Закупок" },
    { title: "suppliersCount", label: "Поставщиков" },
    { title: "participantsCount", label: "Участников" },
    { title: "approvalPercentage", label: "Допусков" },
    { title: "winSum", label: "Сумма побед", minWidth: "170px" },
    { title: "reducePercentage", label: "Снижение цены" },
    { title: "lastParticipationDate", label: "Последнее участие" },
  ];
};

export const sortingPanelSuppliers = (): SortingPanelFieldI[] => {
  return [
    { title: "participationCount", label: "Участий" },
    { title: "winCount", label: "Побед" },
    { title: "participationSum", label: "Сумма участий", minWidth: "170px" },
    { title: "winSum", label: "Сумма побед", minWidth: "170px" },
    { title: "reducePercentage", label: "Снижение цены" },
    { title: "lastParticipationDate", label: "Последнее участие" },
  ];
};
