<template lang="pug">
#table-wrapper
  .table_menu
    .table_mode
      ui-tab(
        v-for="_tab of tabs"
        size="small"
        :key="_tab.mode"
        :active="mode === _tab.mode"
        @click="changeSubTab(_tab.mode)"
      ) {{ _tab.title }}
    .table_save
      ui-button(
        v-if="sortingForm[mode]?.sorting?.length !== 0"
        type="secondary"
        @click="fetch"
      ) Очистить сортировки
      ui-button(
        v-if="enableDownloadAnalyticsExcel"
        type="secondary"
        :icon-left="UiIconNames.Icon_Download"
        :loader="excelLoader"
        @click="$emit('downloadExcel')"
      ) Скачать в Excel
  sorting-panel(
    v-model="sortingForm[mode]"
    :sorting-panel-fields="sortingPanels[mode]"
    @apply-sorting="applySort"
  )
  analytic-table(
    v-if="mode"
    :data="entitiesList[mode] || []"
    :info="entitiesListInfo[mode] || {}"
    :list-name="tab + mode[0].toUpperCase() + mode.slice(1)"
    @load-next-part="$emit('fetchEntity')"
  )
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";
import { useVModel } from "@vueuse/core";
import { getDefaultSortingForm } from "@/utils/getters/defaultFilters";
import { scrollIntoView } from "@/utils/scroller/documentScroller";

import AnalyticTable from "@/components/pages/analytics/table/AnalyticTable.vue";
import UiTab from "@/components/ui/button/UiTab.vue";
import UiButton from "@/components/ui/button/UiButton.vue";
import SortingPanel from "@/components/ui/sorting/SortingPanel.vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";

import type { PropType } from "vue";
import type { TabMode } from "@/stores/search/SearchFormInterface";

interface SubTab {
  title: string,
  mode: string,
}

export default defineComponent({
  name: "TableWrapper",
  components: {
    UiTab,
    UiButton,
    AnalyticTable,
    SortingPanel,
  },
  emits: [
    'resetEntity',
    'fetchEntity',
    'downloadExcel',
    'update:mode',
    'update:sortingForm',
  ],
  props: {
    mode: {
      type: String,
      default: '',
    },
    tab: {
      type: String as PropType<Exclude<TabMode, 'LotsListSearch'|'AnalyseIndustry'>>,
      default: '',
    },
    tabs: {
      type: Array as PropType<SubTab[]>,
      default: () => [],
    },
    excelLoader: {
      type: Boolean,
      default: false,
    },
    sortingForm: {
      type: Object,
      default: () => ({}),
    },
    entitiesList: {
      type: Object,
      default: () => ({}),
    },
    entitiesListInfo: {
      type: Object,
      default: () => ({}),
    },
    sortingPanels: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, context) {
    const route = useRoute();
    const router = useRouter();

    const subTabMode = useVModel(props, 'mode', context.emit)
    const sortingFormRef = useVModel(props, 'sortingForm', context.emit)

    onMounted(() => {
      const currentSubTabMode = route.params.subTab + "";
      const tabs = props.tabs.map(e => e.mode)

      if (!currentSubTabMode || !tabs.includes(currentSubTabMode)) {
        changeSubTab(subTabMode.value ? subTabMode.value : props.tabs[0].mode);
      } else if (subTabMode.value !== currentSubTabMode) {
        changeSubTab(currentSubTabMode);
      }
    });

    function changeSubTab(mode: string) {
      subTabMode.value = mode;
      router.push({
        name: route.name || "",
        params: { subTab: mode },
        query: route.query,
      });
    }

    function fetch() {
      sortingFormRef.value[subTabMode.value] = getDefaultSortingForm();
      context.emit('resetEntity', subTabMode.value)
      context.emit('fetchEntity')
    }

    function applySort() {
      scrollIntoView("table-wrapper", "start", "instant")
      context.emit('resetEntity', subTabMode.value)
      context.emit('fetchEntity')
    }

    const {
      enableDownloadAnalyticsExcel,
    } = useUserAccess();

    return {
      enableDownloadAnalyticsExcel,
      fetch,
      applySort,
      changeSubTab,
      UiIconNames,
    };
  },
});
</script>

<style scoped lang="scss">
#table-wrapper {
  .table_menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 8px;

    .table_mode {
      display: flex;
      align-items: flex-end;
      gap: 1px;
    }

    .table_save {
      display: flex;
      align-items: flex-start;
      gap: 8px;
    }
  }
}

@media (max-width: 1020px) {
  ::v-deep(.ui-tab._small) {
    width: 108px;
  }
}
</style>
