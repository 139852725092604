import type { TabMode } from "@/stores/search/SearchFormInterface";
import {
  getDefaultFilteredEntity,
  getDefaultRequestCommonInfo,
  getEntityListInfo
} from "@/utils/getters/defaultRequestInfo";
import { ref } from "vue";
import { assignSorting, tagsToIdList } from "@/utils/searchForm/searchFormWorker";
import { getCommonCards, getChart, getEntity, resetRequestMeta } from "@/utils/api/analyticsRequests";
import { useAnalyticCustomers } from "@/use/analytics/variables/useAnalyticCustomers";
import { useAnalyticSuppliers } from "@/use/analytics/variables/useAnalyticSuppliers";
import { useAnalyticsIndustry } from "@/use/analytics/variables/useAnalyticsIndustry";

function useAnalyticsVariables(type: Exclude<TabMode, "LotsListSearch">) {
  switch (type) {
    case 'AnalyseCustomers': return useAnalyticCustomers();
    case 'AnalyseSuppliers': return useAnalyticSuppliers();
    case 'AnalyseIndustry': return useAnalyticsIndustry();
  }

  return useAnalyticsIndustry();
}

export function useAnalytics(tab: Exclude<TabMode, "LotsListSearch">) {

  const commonCards = ref({
    data: null,
    info: getDefaultRequestCommonInfo(),
  })

  const monthlyChart = ref(getDefaultFilteredEntity());
  const regionChart = ref(getDefaultFilteredEntity());

  const {
    sortingPanels,
    entitiesLists,
    entitiesListsInfo,
  } = useAnalyticsVariables(tab)

  function fetchEntity(fetchData: any, _mode: string, _tab: string) {
    getEntity(entitiesLists, entitiesListsInfo, _mode, _mode, _tab, fetchData)
  }

  function innerFetchIndustry(fetchData: any, sortingForm: any) {
    getCommonCards(commonCards, 'industry', fetchData)
    getChart(monthlyChart, "industry", "monthly", fetchData)
    getChart(regionChart, "industry", "regions", fetchData)
    getEntity(entitiesLists, entitiesListsInfo, "lots", "lots", "industry", assignSorting(fetchData, sortingForm.lots))
    getEntity(entitiesLists, entitiesListsInfo, "customers", "customers", "industry", assignSorting(fetchData, sortingForm.customers))
    getEntity(entitiesLists, entitiesListsInfo, "suppliers", "suppliers", "industry", assignSorting(fetchData, sortingForm.suppliers))
  }

  function innerFetchSuppliers(searchForm: any, sortingForm: any) {
    const preparedSearchForm = tagsToIdList(searchForm, true)
    getCommonCards(commonCards, 'suppliers', preparedSearchForm)
    getChart(monthlyChart, "suppliers", "monthly", preparedSearchForm)
    getChart(regionChart, "suppliers", "regions", preparedSearchForm)
    getEntity(entitiesLists, entitiesListsInfo, "won", "won", "suppliers", assignSorting(preparedSearchForm, sortingForm.won))
    getEntity(entitiesLists, entitiesListsInfo, "participated", "participated", "suppliers", assignSorting(preparedSearchForm, sortingForm.participated))
    getEntity(entitiesLists, entitiesListsInfo, "customers", "customers", "suppliers", assignSorting(preparedSearchForm, sortingForm.customers))
    getEntity(entitiesLists, entitiesListsInfo, "competitors", "competitors", "suppliers", assignSorting(preparedSearchForm, sortingForm.competitors))
  }

  function innerFetchCustomers(searchForm: any, sortingForm: any) {
    const preparedSearchForm = tagsToIdList(searchForm, true)
    getCommonCards(commonCards, 'customers', preparedSearchForm)
    getChart(monthlyChart, "customers", "monthly", preparedSearchForm)
    getChart(regionChart, "customers", "regions", preparedSearchForm)
    getEntity(entitiesLists, entitiesListsInfo, "actual", "actual", "customers", assignSorting(preparedSearchForm, sortingForm.actual))
    getEntity(entitiesLists, entitiesListsInfo, "completed", "completed", "customers", assignSorting(preparedSearchForm, sortingForm.completed))
    getEntity(entitiesLists, entitiesListsInfo, "participants", "participants", "customers", assignSorting(preparedSearchForm, sortingForm.participants))
    getEntity(entitiesLists, entitiesListsInfo, "suppliers", "suppliers", "customers", assignSorting(preparedSearchForm, sortingForm.suppliers))
  }

  function fetchAllAnalyse(searchForm: any, sortingForm: any) {
    switch (tab) {
      case 'AnalyseSuppliers':
        innerFetchSuppliers(searchForm, sortingForm);
        return;
      case 'AnalyseCustomers':
        innerFetchCustomers(searchForm, sortingForm);
        return;
      case 'AnalyseIndustry':
        innerFetchIndustry(searchForm, sortingForm);
    }
  }

  function resetEntity(key: string) {
    if (entitiesListsInfo.value[key].meta.request) entitiesListsInfo.value[key].meta.request.cancel();
    entitiesLists.value[key] = [];
    entitiesListsInfo.value[key] = getEntityListInfo();
  }

  function resetEntitiesList() {
    Object.keys(entitiesLists.value).forEach(resetEntity);
  }

  function resetFetchingData() {
    resetRequestMeta(commonCards)
    resetRequestMeta(monthlyChart, [])
    resetRequestMeta(regionChart, [])
    resetEntitiesList()
  }

  return {
    commonCards,
    monthlyChart,
    regionChart,
    sortingPanels,
    entitiesLists,
    entitiesListsInfo,
    resetEntity,
    fetchEntity,
    fetchAllAnalyse,
    resetFetchingData,
  }
}
