<template lang="pug">
.lot-card.supplier-card
  analytic-link.company-card__title(tab="customers" :company="item?.customers")
  .lot-card__info
    .lot-card__info-block
      span.card-label ИНН:
      span.info-value {{ item.customers.inn || '-' }}
    .lot-card__info-block
      span.card-label КПП:
      span.info-value {{ item.customers.kpp || '-' }}
    .lot-card__info-block
      span.card-label РЕГИОН:
      span.info-value {{ item.customers.region }}, {{ item.customers.district }}
  .lot-card__participant-count
    .card-label Участий:
    .card-value {{ item.participationCount }}
  .lot-card__not-reject-count
    .card-label Допусков:
    .card-value {{ item.approvalCount }}
    .card-value.lot-card__percentage {{ ((item.approvalCount / item.participationCount) * 100).toFixed(2) }} %
  .lot-card__win-count
    .card-label Побед:
    .card-value {{ item.winCount }}
    .card-value.lot-card__percentage {{ ((item.winCount / item.participationCount) * 100).toFixed(2) }} %
  .lot-card__sum-price-win
    .card-label Сумма побед:
    .card-value {{ costFormatterRu().format(Number(item.winSum)) }}
  .lot-card__price-drop
    .card-label Снижение цены:
    .card-value {{ item.reducePercentage || '0' }} %
  .lot-card__last-participation
    .card-label Последнее участие:
    .card-value {{ formatDateDMY(item.lastParticipationDate) }}
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { costFormatterRu } from "@/utils/formatter/costFormatter";
import { formatDateDMY } from "@/utils/formatter/dateFormatter";

import type { PropType } from "vue";
import type { SupplierCustomerItem } from "@/components/pages/analytics/table/cards/CardsDataInterfaces";

import AnalyticLink from "@/components/ui/links/AnalyticLink.vue";

export default defineComponent({
  name: "AnalyticsSuppliersCustomers",
  components: {
    AnalyticLink,
  },
  methods: {
    formatDateDMY,
    costFormatterRu,
  },
  props: {
    item: {
      type: Object as PropType<SupplierCustomerItem>,
      default: () => ({}),
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/styles/lots/analyticLotCard";

.lot-card {
  grid-template-areas:
    "title           none none none none none none"
    "lot-card__info  participant-count not-reject-count win-count sum-price-win price-drop last-participation"
    "lot-card__info  participant-count not-reject-count win-count sum-price-win price-drop last-participation";
  grid-template-rows: auto auto auto;
  grid-template-columns: auto 72px 80px 60px 146px 120px 140px;
}

@media (max-width: 1180px) {
  .lot-card {
    grid-template-areas:
      "title            title               title              title"
      "lot-card__info   sum-price-win       price-drop         last-participation"
      "lot-card__info   participant-count   not-reject-count   win-count";
    grid-template-rows: auto auto auto;
    grid-template-columns: auto 146px 120px 140px;
    row-gap: 8px;
  }
}
</style>
