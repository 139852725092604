<template lang="pug">
.statistics
  span.statistics-title Статистика
  .chart-type__wrapper
    .chart-selector
      old-tooltip(tooltipPosition="bottom-start")
        .chart-type(
          :class="chartType === 'line-chart' && '_active'"
          @click="!hideLineChart && changeChartType('line-chart')"
        )
          ui-icon(:icon="UiIconNames.Landing_DataAnalysis" :size="18")
          span График
        template(#content v-if="hideLineChart")
          | Для отображения линейнего графика необходимо в фильтре по дате публикации
          br
          | установить интервал, превыщающий один месяц
      .chart-type(
        :class="chartType === 'map-chart' && '_active'"
        @click="changeChartType('map-chart')"
      )
        ui-icon(:icon="UiIconNames.Icon_Map" :size="18")
        span Карта
    year-selector(
      v-if="chartType === 'line-chart'"
      v-model="selectedPeriod"
      :options="listOfYears"
    )
  info-widget-wrapper(
    v-model="chartStat"
    :loader="chartType === 'map-chart' ? mapChartData.info.loading : lineChartData.info.loading"
    :stat-list="statList"
    :data="widgetData"
    :period-label="selectedPeriod === 'all' || chartType === 'map-chart' ? [listOfYears[listOfYears.length - 1], listOfYears[0]] : [selectedPeriod]"
  )
  line-chart(
    v-if="chartType === 'line-chart'"
    :chart-stat="chartStat"
    :tooltip="statList"
    :data="lineChartStatistics"
    :info="lineChartData.info"
  )
    .group-by-years
      ui-checkbox(v-model="groupByYearsCheckbox" id="group-by-years" :disabled="selectedPeriod !== 'all'" label="Сгруппировать по годам")
  map-chart(
    v-if="chartType === 'map-chart'"
    :chart-stat="chartStat"
    :tooltip="statList"
    :data="mapChartData.data"
    :info="mapChartData.info"
  )
</template>

<script lang="ts">
import { computed, defineComponent, inject, ref, toRefs, watch } from "vue";
import { useStatistics } from "@/use/analytics/useStatistics";
import { getDefaultFilteredEntity } from "@/utils/getters/defaultRequestInfo";
import type { TabMode } from "@/stores/search/SearchFormInterface";

import UiIcon from "@/components/ui/icon/UiIcon.vue";
import MapChart from "@/components/pages/analytics/statistics/mapChart/MapChart.vue";
import LineChart from "@/components/pages/analytics/statistics/lineChart/LineChart.vue";
import YearSelector from "@/components/pages/analytics/statistics/lineChart/YearSelector.vue";
import InfoWidgetWrapper from "@/components/pages/analytics/statistics/widgets/InfoWidgetWrapper.vue";

import type { PropType } from "vue";
import type { AnalyseChartState } from "@/components/pages/analytics/statistics/StatisticsInterface";

import UiIconNames from "@/components/ui/icon/UiIconNames";
import UiCheckbox from "@/components/ui/checkbox/UiCheckbox.vue";
import OldTooltip from "~/components/ui/tooltip/OldTooltip.vue";

export default defineComponent({
  name: "Statistics",
  components: {
    OldTooltip,
    UiCheckbox,
    InfoWidgetWrapper,
    YearSelector,
    LineChart,
    MapChart,
    UiIcon,
  },
  props: {
    tab: {
      type: String as PropType<Exclude<TabMode, 'LotsListSearch'>>,
      default: '',
    },
    hideLineChart: {
      type: Boolean,
      default: false,
    },
    lineChartData: {
      type: Object as PropType<AnalyseChartState>,
      default: getDefaultFilteredEntity,
    },
    mapChartData: {
      type: Object as PropType<AnalyseChartState>,
      default: getDefaultFilteredEntity,
    },
  },
  setup(props) {

    const type: TabMode = inject("type");

    const chartType = ref(type === 'AnalyseIndustry' || props.hideLineChart ? "map-chart" : "line-chart");
    const chartStat = ref("lotSummary");

    const selectedPeriod = ref('all');
    const groupByYearsCheckbox = ref(false);

    const { lineChartData, mapChartData } = toRefs(props);

    watch(() => props.hideLineChart, (val) => {
      if (val && chartType.value) changeChartType('map-chart');
    })

    watch(() => lineChartData.value?.info, () => {
      selectedPeriod.value = 'all'
    }, { deep: true })

    const {
      byYear,
      listOfYears,
      summaryByYears,
    } = useStatistics(lineChartData);

    const lineChartStatistics = computed(() => {
      if (selectedPeriod.value === 'all') {
        return groupByYearsCheckbox.value ? summaryByYears.value : lineChartData.value.data;
      } else {
        return byYear.value[selectedPeriod.value];
      }
    });

    const widgetData = computed(() => chartType.value === 'map-chart' ? mapChartData.value.data : lineChartStatistics.value)

    function changeChartType(value: 'map-chart' | 'line-chart') {
      chartType.value = value;
    }

    const statList = props.tab === 'AnalyseIndustry' ? [
      { label: "Количество закупок", stat: "total" },
      { label: "Сумма закупок", stat: "lotSummary", currency: true },
    ] : props.tab === 'AnalyseCustomers' ? [
      { label: "Количество заказов", stat: "total" },
      { label: "Сумма заказов", stat: "lotSummary", currency: true },
    ] : [
      { label: "Количество участий", stat: "total" },
      { label: "Количество побед", stat: "win" },
      { label: "Сумма участий", stat: "lotSummary", currency: true },
      { label: "Сумма побед", stat: "winSummary", currency: true },
    ];

    return {
      byYear,
      statList,
      chartStat,
      chartType,
      selectedPeriod,
      lineChartData,
      mapChartData,
      listOfYears,
      lineChartStatistics,
      widgetData,
      groupByYearsCheckbox,
      changeChartType,
      UiIconNames,
    };
  }
})
</script>

<style scoped lang="scss">
.statistics {
  display: flex;
  flex-flow: column;
  gap: 16px;

  .statistics-title {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 20px;
  }
}

.chart-selector {
  display: flex;
  flex-flow: row;
  gap: 16px;

  .chart-type {
    padding: 12px 16px;
    border-radius: 8px;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    gap: 16px;
    cursor: pointer;

    span {
      font-size: 14px;
      line-height: 18px;
    }

    &._active {
      color: #0681FC;
    }

    &:hover {
      color: #0681FC;
    }
  }
}

.chart-type__wrapper {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
}

.group-by-years {
  display: flex;
  justify-content: right;
}
</style>
