<template lang="pug">
.lot-card.competitor-card
  analytic-link.company-card__title(tab="suppliers" :company="item?.competitors")
  .lot-card__info
    .lot-card__info-block
      span.card-label ИНН:
      span.info-value {{ item.competitors.inn || '-' }}
    .lot-card__info-block
      span.card-label РЕГИОН:
      span.info-value {{ item.competitors.region }}, {{ item.competitors.district }}
  .lot-card__win-count
    .card-label Побед:
    .card-value {{ item.win }}
  .lot-card__intersections
    .card-label Пересечений:
    .card-value {{ item.intersections }}
  .lot-card__sum-price-win
    .card-label Сумма побед:
    .card-value {{ costFormatterRu().format(Number(item.winSummary)) }}
  .lot-card__price-drop
    .card-label Снижение цены:
    .card-value {{ item.reducePercentage || '0' }} %
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { costFormatterRu } from "@/utils/formatter/costFormatter";

import type { PropType } from "vue";
import type { SupplierCompetitorItem } from "@/components/pages/analytics/table/cards/CardsDataInterfaces";

import AnalyticLink from "@/components/ui/links/AnalyticLink.vue";

export default defineComponent({
  name: "AnalyticsSuppliersCompetitors",
  components: {
    AnalyticLink,
  },
  methods: {
    costFormatterRu,
  },
  props: {
    item: {
      type: Object as PropType<SupplierCompetitorItem>,
      default: () => ({}),
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/styles/lots/analyticLotCard";

.lot-card {
  grid-template-areas:
    "title              none none none none"
    "lot-card__info     win-count intersections sum-price-win price-drop"
    "lot-card__info     win-count intersections sum-price-win price-drop";
  grid-template-rows: auto auto auto;
  grid-template-columns: auto 60px 102px 146px 120px;
}

@media (max-width: 1180px) {
  .lot-card {
    grid-template-areas:
      "title              title title title title"
      "lot-card__info     win-count intersections sum-price-win price-drop"
      "lot-card__info     win-count intersections sum-price-win price-drop";
  }
}
</style>
