import { ref } from "vue";
import { getEntityListInfo } from "@/utils/getters/defaultRequestInfo";
import { sortingPanelLots, sortingPanelSuppliers, sortingPanelCustomers } from "@/utils/getters/sortingPanel/industrySortingPanels";

/**
 * Аналитика по отрасли
 * панель сортировок (массивы названий сортировок),
 * подгружаемые данные (массивы с компаниями/лотами, info по запросу)
 */
export function useAnalyticsIndustry() {

  const sortingPanels = {
    lots: sortingPanelLots(),
    customers: sortingPanelCustomers(),
    suppliers: sortingPanelSuppliers(),
  }

  const entitiesLists = ref({
    lots: [],
    customers: [],
    suppliers: [],
  })

  const entitiesListsInfo = ref({
    lots: getEntityListInfo(),
    customers: getEntityListInfo(),
    suppliers: getEntityListInfo(),
  })

  return {
    sortingPanels,
    entitiesLists,
    entitiesListsInfo,
  }
}
