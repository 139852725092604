<template lang="pug">
.string-block-mini
  .more-info
    span.label Заказчик:
    analytic-link.value(v-if="item?.customers" tab="customers" :company="item.customers[0]")
    span.unknown(v-else) Заказчик неизвестен

  .more-info
    span.label Тип закупки:
    span.value(v-if="item?.type && 'unknown'") {{ item.type?.title }}
    span.unknown(v-else) Неизвестен

  .more-info
    span.label Регион закупки:
    span.value(v-if="item?.region") {{ item.region?.title }}
    span.unknown(v-else) Неизвестен

  .more-info
    span.label Площадка:
    a.link(v-if="item?.auctionSite" target="_blank" :href="item.auctionSite.link") {{ item.auctionSite.title }}
    span.unknown(v-else) Площадка неизвестна

  .more-info(v-if="showWinner")
    span.label Победитель:
    analytic-link.value(v-if="item?.winners?.length" tab="suppliers" :company="item.winners[0]")
    span.unknown(v-else) Победитель неизвестен
</template>

<script lang="ts">
import { defineComponent } from "vue";

import type { PropType } from "vue";
import type { AnalyticBaseLotItemInterface } from "@/components/pages/analytics/table/cards/CardsDataInterfaces";
import AnalyticLink from "@/components/ui/links/AnalyticLink.vue";

export default defineComponent({
  name: "AnalyticStringBlock",
  components: {
    AnalyticLink,
  },
  props: {
    item: {
      type: Object as PropType<AnalyticBaseLotItemInterface>,
      default: () => ({}),
    },
    showWinner: {
      type: Boolean,
      default: false,
    },
  },
})
</script>

<style scoped lang="scss">
@import "@/assets/styles/lots/analyticLotCard";
@import "@/assets/styles/lots/stringBlockMini";
@import "@/assets/styles/mixin/links";

.link {
  @include link-mixin;
}
</style>
