<template lang="pug">
.statistics_average
  .card(v-for="(card, index) of cards" :key="index")
    .statistics-average__content
      template(v-for="(block, index) of card" :key="index")
        .statistics-average__content-label {{ block.label }}
        ui-loader(v-if="info.loading" mini)
        .statistics-average__content-num(v-else) {{ getNum(block) }} {{ block.valueStr }}

    Wave.wave(:class="`_gradient${index % 5}`")
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { getDefaultRequestCommonInfo } from "@/utils/getters/defaultRequestInfo";

import type { PropType } from "vue";
import type { RequestCommonInfo } from "@/utils/getters/defaultRequestInfo";

import Wave from "@/public/pics/icon-wave.svg?component";
import UiLoader from "@/components/ui/loader/UiLoader.vue";

export default defineComponent({
  name: "AverageCards",
  components: {
    Wave,
    UiLoader,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    info: {
      type: Object as PropType<RequestCommonInfo>,
      default: getDefaultRequestCommonInfo,
    },
    cards: {
      type: Array,
      default: () => [],
    },
  },

  setup(props) {

    function getNum(block = {}) {
      const options = {
        ...(block.compact
          ? { notation: "compact", compactDisplay: "short" }
          : {}),
        ...(block.currency
          ? { style: "currency", currency: "RUB" }
          : {}),
        ...(block.digits
          ? { maximumFractionDigits: 2, }
          : {}),
      }

      return new Intl.NumberFormat("ru-RU", options).format(+props.data?.[block.valueNum] || 0)
    }

    return {
      getNum,
    }
  }
});
</script>

<style scoped lang="scss">
.statistics_average {
  display: flex;
  justify-content: space-between;
  gap: 24px;

  .card {
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1;
    background: #ffffff;

    border: 1px solid #D4D7DE;
    border-radius: 8px;
    box-sizing: border-box;
    overflow: hidden;
  }

  .statistics-average__content {
    display: flex;
    flex-direction: column;
    gap: 8px;

    align-items: center;
    justify-content: center;
    padding: 24px 16px 32px 16px;
    box-sizing: border-box;
    z-index: 1;

    .statistics-average__content-label {
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      flex-grow: 1;
    }

    .statistics-average__content-num {
      display: inline;
      font-size: 24px;
      line-height: 30px;
      color: var(--main-color-blue3);
      flex-grow: 1;
    }
  }
}

.wave {
  position: absolute;
  bottom: 0;
  width: 100%;
  color: white;

  &._gradient0 {
    background: linear-gradient(90deg, #d08e82 0%, #c738cc 100%);
  }

  &._gradient1 {
    background: linear-gradient(90deg, #a9db89 0%, #42d5ab 100%);
  }

  &._gradient2 {
    background: linear-gradient(90deg, #9243f6 0%, #3cd2f3 100%);
  }

  &._gradient3 {
    background: linear-gradient(90deg, #fa709a 0%, #fee140 100%);
  }

  &._gradient4 {
    background: linear-gradient(90deg, #ff5a3d 0%, #ffa9b8 100%);
  }
}

@media (max-width: 980px) {
  .statistics_average {
    gap: 8px;

    .statistics-average__content {
      .statistics-average__content-num {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }
}
</style>
