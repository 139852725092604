<template lang="pug">
ui-select.year-select(
  field="label"
  track-by="value"
  label="Диапазон данных"
  :model-value="modelValue"
  :options="innerOptions"
  @update:modelValue="$emit('update:modelValue', $event)"
)
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";

import type { PropType} from "vue";
import UiSelect from "@/components/ui/select/select/UiSelect.vue";

export default defineComponent({
  name: "YearSelector",
  components: {
    UiSelect,
  },
  emits: [
    'update:modelValue',
  ],
  props: {
    modelValue: {},
    options: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  setup(props) {

    const innerOptions = computed(() => [{
      label: 'Весь период',
      value: 'all',
    }].concat(props.options?.map(e => {
      return {
        label: e,
        value: e,
      }
    })))

    return {
      innerOptions,
    }
  }
})
</script>

<style scoped lang="scss">
.year-select {
  flex-flow: row;
  align-items: center;
  gap: 16px;
  width: auto;

  ::v-deep(.ui-select) {
    width: 320px;
  }
}
</style>
