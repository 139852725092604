import { useAbort, useApi } from "@/use/api/useApi";
import { getDefaultRequestCommonInfo } from "@/utils/getters/defaultRequestInfo";
import type { Ref } from "vue";
import type { FilteredEntity } from "@/utils/getters/defaultRequestInfo";

function preRequestMeta(state: Ref<FilteredEntity>, cancel: Function) {
  if (state.value.info.request) state.value.info.request.cancel();

  state.value.info.loading = true;
  state.value.info.error = false;
  state.value.info.request = { cancel: cancel }
}

function afterRequestMeta(state: Ref<FilteredEntity>, error = false) {
  state.value.info.loading = false;
  state.value.info.error = error;
  state.value.info.request = null;
}

function getCommon(meta: Ref<FilteredEntity>, route: string, payload?: any) {
  const { signal, abort, } = useAbort();

  preRequestMeta(meta, abort)

  useApi().analytics.fetchCommon<any[]>(route, payload, signal)
    .then((data) => {
      if (data) meta.value.data = data
      afterRequestMeta(meta)
    })
    .catch((error) => {
      if (!signal.aborted) afterRequestMeta(meta, true)
    })
}

export function resetRequestMeta(meta: Ref<FilteredEntity>, data : any = null) {
  if (meta.value.info.request) meta.value.info.request.cancel();
  meta.value.data = data;
  meta.value.info = getDefaultRequestCommonInfo();
}

export function getCommonCards(meta: Ref<FilteredEntity>, tab: string, payload?: any) {
  getCommon(meta, `analytics/${tab}/common`, payload)
}

export function getChart(meta: Ref<FilteredEntity>, tab: "suppliers" | "customers" | "industry", url: "monthly" | "regions", payload?: any) {
  getCommon(meta, `analytics/${tab}/${url}`, payload)
}

export function getEntity(entitiesLists: Ref<any>, entitiesListsInfo: Ref<any>, mode: string, path: string, tab: string, payload: any, id?: number) {
  if (!entitiesListsInfo.value[mode].meta.isOver) {
    const { signal, abort } = useAbort();

    if (entitiesListsInfo.value[mode].meta.request) {
      entitiesListsInfo.value[mode].meta.request.cancel();
    }

    entitiesListsInfo.value[mode].meta.loading = true
    entitiesListsInfo.value[mode].meta.error = false
    entitiesListsInfo.value[mode].meta.request = { cancel: abort };

    // todo what is backendId
    const backendId = id ? `/${id}` : "";
    const route = `analytics/${tab + backendId}/${path}`;

    useApi().common.fetchEntitiesList<any[]>(route, payload, entitiesListsInfo.value[mode].meta.part, signal)
      .then((response) => {
        const data = response || []

        if (data.length) {
          entitiesLists.value[mode].push(...data);
          entitiesListsInfo.value[mode].options.info.count += data.length;
          entitiesListsInfo.value[mode].meta.part++;
        } else entitiesListsInfo.value[mode].meta.isOver = true;
      })
      .catch((error) => {
        if (!signal.aborted) entitiesListsInfo.value[mode].meta.error = true;
      })
      .finally(() => {
        if (!signal.aborted) {
          entitiesListsInfo.value[mode].meta.loading = false;
          entitiesListsInfo.value[mode].meta.request = null;
        }
      })
  }
}
