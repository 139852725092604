<template lang="pug">
.inner-chart-wrapper
  .chart-info(v-if="info?.loading" v-loading="info?.loading")
  .chart-info(v-else-if="info?.error") При загрузке графика произошла непредвиденная ошибка.
  .chart-info(v-else-if="!data || data.length === 0") Данные для графика отсутствуют.
  .v-chart-wrapper(v-show="!info.loading && !info.error && data?.length")
    slot
    VChart.chart(autoresize :option="option")
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from "vue";
import { use } from "echarts/core";

import VChart from "vue-echarts";
import YearSelector from "@/components/pages/analytics/statistics/lineChart/YearSelector.vue";

import { GridComponent, TooltipComponent, DatasetComponent } from "echarts/components";
import { LineChart } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";

import type { PropType } from "vue";
import type { RequestCommonInfo } from "@/utils/getters/defaultRequestInfo";

use([
  GridComponent,
  LineChart,
  UniversalTransition,
  CanvasRenderer,
  TooltipComponent,
  DatasetComponent,
]);

export default defineComponent({
  name: 'LineChart',
  components: {
    YearSelector,
    VChart,
  },
  props: {
    chartStat: {
      type: String,
      default: "lotSummary",
    },
    tooltip: {
      type: Object,
      default: () => ({}),
    },
    data: {
      type: Array,
      default: () => [],
    },
    info: {
      type: Object as PropType<RequestCommonInfo>,
      default: () => ({}),
    },
  },
  setup(props: any) {

    // option vars
    const option = ref({});

    // "date", "lotSummary", "total", "win", "winSummary"
    const dimensions = ["date", ...props.tooltip.map((a: any) => a.stat)];

    const newOptions = computed(() => ({
      xAxis: {
        type: "time",
      },
      yAxis: {
        type: "value",
      },
      grid: {
        bottom: 20,
        left: "10%",
        right: "5%",
      },
      dataset: {
        dimensions: dimensions,
        source: props.data,
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          animation: false,
        },
        show: true,
        formatter: function (params: any) {
          const nf = new Intl.NumberFormat("ru-RU", {
            style: "currency",
            currency: "RUB",
            maximumFractionDigits: 2,
          });
          const nfdec = new Intl.NumberFormat("ru-RU", { style: "decimal" });

          const a = props.tooltip
            .map(
              (d: any) =>
                `<div style="display: flex; justify-content: space-between; gap: 16px"><span style="font-weight: ${
                  d.stat === props.chartStat ? "bold" : "normal"
                }">${d.label}:</span><strong>${
                  d.currency
                    ? nf.format(params[0].data[d.stat])
                    : nfdec.format(params[0].data[d.stat])
                }</strong></div>`
            )
            .join("\n");

          return `<div style="display: flex; flex-flow: column; gap: 8px; min-width: 140px;"><div style="font-weight: bold">${
            params[0].marker + params[0].data["date"]
          }</div><div>${a}</div></div>`;
        },
      },
      series: [{
        type: "line",
        smooth: true,
        datasetIndex: 0,
        encode: {
          y: props.chartStat,
          x: "date",
        },
      }],
    }));

    option.value = newOptions.value;

    watch(newOptions, () => {
      option.value = newOptions.value;
    });

    return {
      option,
    };
  }
})
</script>

<style scoped lang="scss">
.inner-chart-wrapper {
  height: 444px;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid #D4D7DE;
  padding: 12px 16px 32px 16px;
  background-color: white;
}

.chart {
  width: 100%;
  height: 444px;
}

.chart-info {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  height: inherit;
  width: inherit;
}

.v-chart-wrapper {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
}
</style>
