<template lang="pug">
.filter-and-sorting-panel._sticky
  .sort-main
  sort-item(
    v-for="field of preparedSortingPanel"
    :key="field.title"
    :field="field"
    :sorting="preFiltrationForm.sorting"
    @set-sorting="setSort"
  )
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useVModel } from "@vueuse/core";

import SortItem from "@/components/ui/sorting/SortItem.vue";
import type { PropType } from "vue";
import type { SortingFieldExtendedI, SortingFormI, SortingPanelFieldI } from "@/utils/getters/defaultFilters";
import type { SortItemI, SortingMode } from "@/utils/getters/defaultFilters";

export default defineComponent({
  name: "SortingPanel",
  components: {
    SortItem,
  },
  emits: [
    'applySorting',
  ],
  props: {
    modelValue: {
      type: Object as PropType<SortingFormI>,
      default: () => ({}),
    },
    sortingPanelFields: {
      type: Array as PropType<SortingPanelFieldI[]>,
      default: () => [],
    },
  },
  setup(props, context) {

    const preFiltrationForm = useVModel<SortingFormI>(props, 'modelValue', context.emit);

    function setSorting(field: string, mode: 'asc'|'desc'|'', _sorting: Array<SortItemI>) {
      const result = _sorting.filter((value) => value.title !== field);
      if (mode !== "") {
        result.push({ title: field, order: mode })
      }
      return result;
    }

    function setNextSorting(field: string, mode: SortingMode) {
      preFiltrationForm.value.sorting = setSorting(field, mode, preFiltrationForm.value.sorting);
      context.emit('applySorting');
    }

    function setSort(p: { field: string, mode: SortingMode }) {
      setNextSorting(p.field, p.mode)
    }

    const preparedSortingPanel = computed<SortingFieldExtendedI[]>(() =>
      props.sortingPanelFields.map((v: any) => ({
        ...v,
        sortIndex: preFiltrationForm.value?.sorting?.findIndex(
          (filter: any) => filter.title === v.title
        ),
      }))
    );

    return {
      preFiltrationForm,
      preparedSortingPanel,
      setSort,
    };
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/styles/filters/panels";

.sort-main {
  box-sizing: border-box;
  border: 1px solid #c6e2ff;
  width: 100%;
}

.filter-and-sorting-panel {
  padding-top: 0 !important;
  background-color: #ecf5ff !important;
}
</style>
