<template>
  <teleport to="body">
    <div
      class="pulse-marker"
      :style="{ top: pointY + 'px', left: pointX + 'px' }"
    >
      <strong>
        {{ region?.name }}
      </strong>
      <template v-for="(item, index) of tooltip" :key="index">
        <div v-if="region[item.stat]" class="pulse-marker__row">
          <div :class="chartStat === item.stat && 'pulse-marker__strong'">
            {{ item.label }}
          </div>
          <strong>
            {{
              item.currency
                ? nf.format(region[item.stat])
                : nfdec.format(region[item.stat])
            }}
          </strong>
        </div>
      </template>
    </div>
  </teleport>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    pointX: Number,
    pointY: Number,
    region: Object,
    tooltip: {
      type: Object,
      default: () => ({}),
    },
    chartStat: {
      type: String,
      default: "lotSummary",
    },
  },
  setup() {
    const nf = new Intl.NumberFormat("ru-RU", {
      style: "currency",
      currency: "RUB",
      maximumFractionDigits: 2,
    });
    const nfdec = new Intl.NumberFormat("ru-RU", { style: "decimal" });

    return { nf, nfdec };
  },
});
</script>

<style lang="scss">
.pulse-marker {
  display: flex;
  flex-direction: column;
  position: fixed;
  gap: 8px;
  padding: 10px;
  border: 1px solid white;
  box-shadow: #00000033 1px 2px 10px;
  border-radius: 4px;
  background-color: white;
  font-size: 14px;
  height: min-content;
  width: 240px;
  z-index: 9999999;
  transition: opacity 0.2s cubic-bezier(0.23, 1, 0.32, 1) 0s,
    visibility 0.2s cubic-bezier(0.23, 1, 0.32, 1) 0s,
    transform 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s;

  .pulse-marker__row {
    display: flex;
    justify-content: space-between;
    gap: 16px;
  }

  .pulse-marker__strong {
    font-weight: bold;
  }
}
</style>
