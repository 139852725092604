import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 202 34"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#fff",
      d: "M0 17.343V0h202v16.352c0 .496-5.967-14.772-20.894-14.772-4.478 0-11.443 1.45-20.4 8.157-25.989 19.46-43.893 15.812-54.234 10.694C72.142 3.441 67.663 33 38.805 33.965 25.876 34.398 5.229 30.878 0 17.343Z"
    }, null, -1)
  ])))
}
export default { render: render }