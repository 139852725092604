import type { SortingPanelFieldI } from "@/utils/getters/defaultFilters";

export const sortingPanelWin = (): SortingPanelFieldI[] => {
  return [
    { title: "lotType", label: "Тип" },
    { title: "lotLaw", label: "Закон" },
    { title: "dateResult", label: "Дата итогов" },
    { title: "participantsCount", label: "Участников" },
    { title: "cost", label: "Начальная цена", minWidth: "170px" },
    { title: "offeredPrice", label: "Предложенная цена", minWidth: "170px" },
  ];
};

export const sortingPanelParticipated = (): SortingPanelFieldI[] => {
  return [
    { title: "lotType", label: "Тип" },
    { title: "lotLaw", label: "Закон" },
    { title: "dateResult", label: "Дата итогов" },
    { title: "participantsCount", label: "Участников" },
    { title: "cost", label: "Начальная цена", minWidth: "170px" },
    { title: "offeredPrice", label: "Предложенная цена", minWidth: "170px" },
  ];
};

export const sortingPanelCustomers = (): SortingPanelFieldI[] => {
  return [
    { title: "participationCount", label: "Участий" },
    { title: "approvalCount", label: "Допусков" },
    { title: "winCount", label: "Побед" },
    { title: "winSum", label: "Сумма побед", minWidth: "170px" },
    { title: "reducePercentage", label: "Снижение цены" },
    { title: "lastParticipationDate", label: "Последнее участие" },
  ];
};

export const sortingPanelCompetitors = (): SortingPanelFieldI[] => {
  return [
    { title: "win", label: "Побед" },
    { title: "intersections", label: "Пересечений" },
    { title: "winSummary", label: "Сумма побед", minWidth: "170px" },
    { title: "reducePercentage", label: "Снижение цены" },
  ];
};
