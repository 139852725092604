<template>
  <path
    ref="regionPath"
    :index="index"
    :d="region.path"
    :class="['state', region.isCity && 'city']"
    @mouseover="mouseover"
    @mouseout="mouseout"
    @mousemove="$emit('mousemove', $event)"
  />
</template>

<script lang="ts">
import { defineComponent, ref, watch, computed } from "vue";

export default defineComponent({
  props: {
    region: {
      type: Object,
      required: true,
    },
    index: Number,
    maxKey: {
      type: Number,
      required: true,
    },
    chartStat: {
      type: String,
      required: true,
    },
  },
  emits: [
    "showTooltip",
    "hideTooltip",
    "mousemove",
  ],
  setup(props, context) {

    const regionPath = ref(null);

    function mouseover() {
      context.emit("showTooltip", { region: props.region });
    }

    function mouseout() {
      context.emit("hideTooltip");
    }

    const maxKeyProp = computed(() => props.maxKey);

    watch(maxKeyProp, () => {

      let saturation = props.maxKey
        ? Math.cbrt((+props.region[props.chartStat] || 0) / +props.maxKey) * 50
        : 0;

      let lightness =
        (1 - (+props.region[props.chartStat] || 0) / +props.maxKey) * 50 + 30;

      if (saturation) saturation += 50
      else lightness = 95

      setTimeout(() => {
        if (regionPath.value) {
          // @ts-ignore
          regionPath.value.style.fill = `hsl(205, ${saturation}%, ${lightness}%)`;
        }
      }, 0);

    }, { immediate: true });

    return {
      regionPath,
      mouseover,
      mouseout,
    };
  },
});
</script>

<style scoped lang="scss">
</style>
