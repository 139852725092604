<template lang="pug">
.panel-item.sort(
  :class="!!sorting[field.sortIndex] && '_active'"
  :style="'min-width: ' + field.minWidth"
  @click="setNextSorting(field.title, sorting?.[field.sortIndex]?.order || '')"
)
  ui-icon(
    clickable
    :icon="getSortingIcon(sorting?.[field.sortIndex]?.order || '')"
  )
  .name
    | {{ field.label }}
    .badge(v-if="field.sortIndex !== -1") {{ field.sortIndex + 1 }}
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { getNextSortMode, getSortingIcon } from "@/utils/filters/sorting";
import type { PropType } from "vue";
import type { SortingFieldExtendedI, SortItemI } from "@/utils/getters/defaultFilters";
import UiIcon from "@/components/ui/icon/UiIcon.vue";

export default defineComponent({
  name: "SortItem",
  components: {
    UiIcon,
  },
  methods: {
    getSortingIcon,
  },
  emits: [
    'setSorting',
  ],
  props: {
    field: {
      type: Object as PropType<SortingFieldExtendedI>,
      default: () => ({}),
    },
    sorting: {
      type: Array as PropType<SortItemI[]>,
      default: () => [],
    },
  },
  setup(props, context) {

    function setNextSorting(field: string, mode: 'asc'|'desc'|'') {
      context.emit('setSorting', { field: field, mode: getNextSortMode(mode) })
    }

    return {
      setNextSorting,
    }
  }
})
</script>

<style scoped lang="scss">
@import "@/assets/styles/filters/panels";
</style>
