<template lang="pug">
.lot-card.customer-card
  analytic-link.company-card__title(tab="customers" :company="item?.customers")
  .lot-card__info
    .lot-card__info-block
      span.card-label ИНН:
      span.info-value {{ item.customers?.inn || '-' }}
    .lot-card__info-block
      span.card-label КПП:
      span.info-value {{ item.customers?.kpp || '-' }}
    .lot-card__info-block
      span.card-label РЕГИОН:
      span.info-value {{ item.customers?.region }}, {{ item.customers?.district }}
  .count-block
    .lot-card__lots-count.count
      .card-label Закупок:
      .card-value {{ item.lotsCount }}
    .lot-card__suppliers-count.count
      .card-label Поставщиков:
      .card-value {{ item.suppliersCount }}
    .lot-card__participant-count.count
      .card-label Участников:
      .card-value {{ item.participantsCount }}
  .lot-card__percent-admittance
    .card-label Допусков:
    .card-value {{ item.approvalPercentage }} %
  .lot-card__sum-price-win
    .card-label Сумма побед:
    .card-value {{ costFormatterRu().format(Number(item.winSum)) }}
  .lot-card__price-drop
    .card-label Снижение цены:
    .card-value {{ item.reducePercentage || '0' }} %
  .lot-card__last-participation
    .card-label Последнее участие:
    .card-value {{ formatDateDMY(item.lastParticipationDate) }}
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { costFormatterRu } from "@/utils/formatter/costFormatter";
import { formatDateDMY } from "@/utils/formatter/dateFormatter";
import type { PropType } from "vue";
import type { IndustryCustomerItem } from "@/components/pages/analytics/table/cards/CardsDataInterfaces";
import AnalyticLink from "@/components/ui/links/AnalyticLink.vue";

export default defineComponent({
  name: "AnalyticsIndustryCustomers",
  components: {
    AnalyticLink,
  },
  methods: {
    formatDateDMY,
    costFormatterRu,
  },
  props: {
    item: {
      type: Object as PropType<IndustryCustomerItem>,
      default: () => ({}),
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/styles/lots/analyticLotCard";

.count {
  flex-flow: row !important;
  gap: 4px !important;
  line-height: 16px;
}

.count-block {
  display: flex;
  flex-flow: column;
  gap: 8px;

  grid-area: count-block;
}

.lot-card {
  grid-template-areas:
    "title            none                  none                 none              none          none"
    "lot-card__info   count-block           percent-admittance   sum-price-win     price-drop    last-participation";
  grid-template-rows: auto auto;
  grid-template-columns: auto 140px 80px 146px 120px 140px;
}

@media (max-width: 1180px) {
  .lot-card {
    grid-template-areas:
      "title            title            title                 title"
      "lot-card__info   count-block      price-drop            last-participation"
      "lot-card__info   count-block      percent-admittance    sum-price-win";
    grid-template-rows: auto auto auto;
    grid-template-columns: auto 140px 120px 140px;
  }
}
</style>
