<template lang="pug">
.map-chart-wrapper
  .chart-info(v-if="info?.loading" v-loading="info?.loading")
  .chart-info(v-else-if="info?.error") При загрузке графика произошла непредвиденная ошибка.
  .chart-info(v-else-if="data?.length === 0") Данные для графика отсутствуют.
  RussianMap(
    v-else
    :data="data"
    :chart-stat="chartStat"
    @mousemove="mousemove"
    @show-tooltip="goEvent('showTooltip', $event)"
    @hide-tooltip="goEvent('hideTooltip')"
  )
PulseMarker(
  v-if="position.x && position.y"
  :point-x="position.x"
  :point-y="position.y"
  :region="region"
  :tooltip="tooltip"
  :chart-stat="chartStat"
)
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from "vue";

import RussianMap from "./RussianMap.vue";
import PulseMarker from "./PulseMarker.vue";

import type { PropType } from "vue";
import type { RequestCommonInfo } from "@/utils/getters/defaultRequestInfo";

export default defineComponent({
  name: 'MapChart',
  components: {
    RussianMap,
    PulseMarker,
  },
  props: {
    chartStat: {
      type: String,
      default: "lotSummary",
    },
    tooltip: {
      type: Object,
      default: () => ({}),
    },
    data: {
      type: Array,
      default: () => [],
    },
    info: {
      type: Object as PropType<RequestCommonInfo>,
      default: () => ({}),
    },
  },
  setup() {

    const position = reactive({
      x: 0,
      y: 0,
    });

    const region = ref({
      RegionCode: "",
      date: "",
      fed_okrug: "",
      lotSummary: "",
      name: "",
      total: 0,
      win: 0,
      winSummary: 0,
    });

    function goEvent(eventName: any, event?: any) {
      if (eventName === "showTooltip") {
        region.value = event.region;
      } else {
        position.x = undefined;
      }
    }

    function mousemove(event: any) {
      position.x = event.clientX + 10;
      position.y = event.clientY + 10;
    }

    return {
      position,
      region,
      goEvent,
      mousemove,
    };
  },
});
</script>

<style scoped lang="scss">
.map-chart-wrapper {
  height: 444px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid #D4D7DE;
  padding: 12px 16px 32px 16px;
  background-color: white;
}

.chart-info {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  height: inherit;
  width: inherit;
}
</style>
