import { ref } from "vue";
import { getEntityListInfo } from "@/utils/getters/defaultRequestInfo";
import {
  sortingPanelCompetitors,
  sortingPanelCustomers,
  sortingPanelParticipated,
  sortingPanelWin
} from "@/utils/getters/sortingPanel/suppliersSortingPanels";

/**
 * Аналитика по поставщику
 * панель сортировок (массивы названий сортировок),
 * подгружаемые данные (массивы с компаниями/лотами, info по запросу)
 */
export function useAnalyticSuppliers() {

  const sortingPanels = {
    won: sortingPanelWin(),
    participated: sortingPanelParticipated(),
    customers: sortingPanelCustomers(),
    competitors: sortingPanelCompetitors(),
  }

  const entitiesLists = ref({
    won: [],
    participated: [],
    customers: [],
    competitors: [],
  })

  const entitiesListsInfo = ref({
    won: getEntityListInfo(),
    participated: getEntityListInfo(),
    customers: getEntityListInfo(),
    competitors: getEntityListInfo(),
  })

  return {
    sortingPanels,
    entitiesLists,
    entitiesListsInfo,
  }
}
