<template lang="pug">
.lot-card.supplier-card
  analytic-link.company-card__title(tab="suppliers" :company="item?.suppliers")
  .lot-card__info
    .lot-card__info-block
      span.card-label ИНН:
      span.info-value {{ item.suppliers?.inn || '-' }}
    .lot-card__info-block
      span.card-label КПП:
      span.info-value {{ item.suppliers?.kpp || '-' }}
    .lot-card__info-block
      span.card-label РЕГИОН:
      span.info-value {{ item.suppliers?.region }}, {{ item.suppliers?.district }}
  .lot-card__participant-count
    .card-label Участий:
    .card-value {{ item.participationCount }}
  .lot-card__win-count
    .card-label Побед:
    .card-value {{ item.winCount }}
  .lot-card__sum-price-participation
    .card-label Сумма участий:
    .card-value {{ costFormatterRu().format(Number(item.participationSum)) }}
  .lot-card__sum-price-win
    .card-label Сумма побед:
    .card-value {{ costFormatterRu().format(Number(item.winSum)) }}
  .lot-card__price-drop
    .card-label Снижение цены:
    .card-value {{ item.reducePercentage }} %
  .lot-card__last-participation
    .card-label Последнее участие:
    .card-value {{ formatDateDMY(item.lastParticipationDate) }}
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { costFormatterRu } from "@/utils/formatter/costFormatter";
import { formatDateDMY } from "@/utils/formatter/dateFormatter";

import type { PropType } from "vue";
import type { IndustrySupplierItem } from "@/components/pages/analytics/table/cards/CardsDataInterfaces";

import AnalyticLink from "@/components/ui/links/AnalyticLink.vue";

export default defineComponent({
  name: "AnalyticsIndustrySuppliers",
  components: {
    AnalyticLink,
  },
  methods: {
    formatDateDMY,
    costFormatterRu,
  },
  props: {
    item: {
      type: Object as PropType<IndustrySupplierItem>,
      default: () => ({}),
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/styles/lots/analyticLotCard";

.lot-card {
  grid-template-areas:
    "title          none none none none none none"
    "lot-card__info participant-count win-count sum-price-participation sum-price-win price-drop last-participation";
  grid-template-rows: auto auto;
  grid-template-columns: auto 72px 60px 146px 146px 146px 120px;
}

@media (max-width: 1180px) {
  .lot-card {
    grid-template-areas:
      "title             title                   title         title"
      "lot-card__info    participant-count       win-count     last-participation"
      "lot-card__info    sum-price-participation sum-price-win price-drop";
    grid-template-rows: auto auto auto;
    grid-template-columns: auto 146px 146px 120px;
  }
}
</style>
