<template lang="pug">
list-wrapper(
  in-analytics
  :info="info"
  :count="data.length"
  @reload="$emit('loadNextPart')"
  @load-next-part="$emit('loadNextPart')"
)
  component.analyse-table__item(
    v-for="(item, index) of data"
    :key="item.id || index"
    :index="index"
    :is="listName"
    :item="item"
  )
</template>

<script lang="ts">
import { defineComponent } from "vue";

import type { PropType } from "vue";
import type { MainStreamListInfo } from "@/utils/getters/defaultRequestInfo";

import AnalyseIndustryLots from "@/components/pages/analytics/table/cards/AnalyseLotItem.vue"
import AnalyseIndustryCustomers from "@/components/pages/analytics/table/cards/AnalyseIndustryCustomers.vue"
import AnalyseIndustrySuppliers from "@/components/pages/analytics/table/cards/AnalyseIndustrySuppliers.vue"

import AnalyseSuppliersWon from "@/components/pages/analytics/table/cards/AnalyseSuppliersWon.vue"
import AnalyseSuppliersParticipated from "@/components/pages/analytics/table/cards/AnalyseSuppliersParticipated.vue"
import AnalyseSuppliersCustomers from "@/components/pages/analytics/table/cards/AnalyseSuppliersCustomers.vue"
import AnalyseSuppliersCompetitors from "@/components/pages/analytics/table/cards/AnalyseSuppliersCompetitors.vue"

import AnalyseCustomersActual from "@/components/pages/analytics/table/cards/AnalyseCustomersActual.vue"
import AnalyseCustomersCompleted from "@/components/pages/analytics/table/cards/AnalyseLotItem.vue"
import AnalyseCustomersSuppliers from "@/components/pages/analytics/table/cards/AnalyseCustomersSuppliers.vue"
import AnalyseCustomersParticipants from "@/components/pages/analytics/table/cards/AnalyseCustomersSuppliers.vue"
import ListWrapper from "@/components/mainStreamPage/metaInfo/ListWrapper.vue";

export default defineComponent({
  name: "AnalyticTable",
  components: {
    ListWrapper,
    AnalyseIndustryLots,
    AnalyseIndustryCustomers,
    AnalyseIndustrySuppliers,
    AnalyseSuppliersWon,
    AnalyseSuppliersParticipated,
    AnalyseSuppliersCustomers,
    AnalyseSuppliersCompetitors,
    AnalyseCustomersActual,
    AnalyseCustomersCompleted,
    AnalyseCustomersSuppliers,
    AnalyseCustomersParticipants,
  },
  props: {
    data: {
      type: Array,
    },
    listName: {
      type: String,
    },
    info: {
      type: Object as PropType<MainStreamListInfo>,
      default: () => ({}),
    },
  },
  emits: [
    'loadNextPart',
  ],
  setup(props, context) {

    function loadNextPart() {
      if (!props.info.meta.loading && !props.info.meta.error) {
        context.emit('loadNextPart')
      }
    }

    return { loadNextPart };
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/styles/lots/lotsList";

.analyse-table__item {
  border: 1px solid #D4D7DE;
  border-radius: 2px;
  padding: 12px;
}
</style>
