import { ref } from "vue";
import { getEntityListInfo } from "@/utils/getters/defaultRequestInfo";
import {
  sortingPanelActual,
  sortingPanelCompleted,
  sortingPanelParticipants,
  sortingPanelSuppliers
} from "@/utils/getters/sortingPanel/customersSortingPanels";

/**
 * Аналитика по заказчику
 * панель сортировок (массивы названий сортировок),
 * подгружаемые данные (массивы с компаниями/лотами, info по запросу)
 */
export function useAnalyticCustomers() {

  const sortingPanels = {
    actual: sortingPanelActual(),
    completed: sortingPanelCompleted(),
    suppliers: sortingPanelSuppliers(),
    participants: sortingPanelParticipants(),
  }

  const entitiesLists = ref({
    actual: [],
    completed: [],
    suppliers: [],
    participants: [],
  })

  const entitiesListsInfo = ref({
    actual: getEntityListInfo(),
    completed: getEntityListInfo(),
    suppliers: getEntityListInfo(),
    participants: getEntityListInfo(),
  })

  return {
    sortingPanels,
    entitiesLists,
    entitiesListsInfo,
  }
}
