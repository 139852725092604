import type { SortingPanelFieldI } from "@/utils/getters/defaultFilters";

export const sortingPanelActual = (): SortingPanelFieldI[] => {
  return [
    {title: "lotType", label: "Тип"},
    {title: "lotStatus", label: "Этап"},
    {title: "lotLaw", label: "Закон"},
    {title: "cost", label: "Начальная цена", minWidth: "170px"},
    {title: "daysUntilEnd", label: "Дней до окончания"},
    {title: "dateResult", label: "Дата итогов"},
  ];
};

export const sortingPanelSuppliers = (): SortingPanelFieldI[] => {
  return [
    {title: "participationCount", label: "Участий"},
    {title: "approvalCount", label: "Допусков"},
    {title: "winCount", label: "Побед"},
    {title: "winSum", label: "Сумма побед", minWidth: "170px" },
    {title: "reducePercentage", label: "Снижение цены"},
    {title: "lastParticipationDate", label: "Последнее участие"},
  ];
};

export const sortingPanelParticipants = (): SortingPanelFieldI[] => {
  return [
    {title: "participationCount", label: "Участий"},
    {title: "approvalCount", label: "Допусков"},
    {title: "winCount", label: "Побед"},
    {title: "winSum", label: "Сумма побед", minWidth: "170px"},
    {title: "reducePercentage", label: "Снижение цены"},
    {title: "lastParticipationDate", label: "Последнее участие"},
  ];
};

export const sortingPanelCompleted = (): SortingPanelFieldI[] => {
  return [
    {title: "lotType", label: "Тип"},
    {title: "lotLaw", label: "Закон"},
    {title: "dateResult", label: "Дата итогов"},
    {title: "participantsCount", label: "Участников"},
    {title: "cost", label: "Начальная цена", minWidth: "170px"},
    {title: "offeredPrice", label: "Предложенная цена", minWidth: "170px"},
  ];
};
