<template lang="pug">
.widget-wrapper
  .info-widget(
    v-for="widget of innerData"
    :key="widget.label"
    :class="modelValue === widget.stat && '_active'"
    @click="$emit('update:modelValue', widget.stat)"
  )
    .main-data
      span.title {{ widget.label }}
      ui-loader(v-if="loader" mini)
      .value-wrapper(v-else)
        .value {{ widget.currency ? costFormatter(innerData[0]?.currency?.alpha || "RUB").format(widget.value) : numberWithSpaces(widget.value) }}
        .percent(v-if="widget.percent") {{ widget.percent }} %
    .period(v-if="!loader") {{ periodLabel.filter(a => a).join(' - ') }}
</template>

<script lang="ts">
import type { PropType } from "vue";
import { computed, defineComponent } from "vue";
import { costFormatter } from "@/utils/formatter/costFormatter";
import { numberWithSpaces } from "@/utils/formatter/numberFormatter";
import { getValue, floatToFixed } from "@/use/analytics/useStatistics";
import UiLoader from "@/components/ui/loader/UiLoader.vue";

export default defineComponent({
  name: "InfoWidgetWrapper",
  components: {
    UiLoader,
  },
  emits: [
    'update:modelValue',
  ],
  props: {
    modelValue: {},
    statList: {
      type: Object,
      default: () => ({}),
    },
    data: {
      type: Array,
      default: () => [],
    },
    periodLabel: {
      type: Array as PropType<string[]>,
      default: ['Весь период'],
    },
    loader: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {

    function getPercentParam(param: any) {
      if (param === 'winSummary') {
        return 'lotSummary';
      } else if (param === 'win') {
        return 'total';
      } else {
        return null;
      }
    }

    const innerData = computed(() => {
      const withValue = props.statList.map((e: any) => {
        return {
          ...e,
          value: getValue(props.data, e.stat),
        };
      })

      return withValue.map((e: any) => {
        return {
          ...e,
          percent: getPercentParam(e.stat) ? (floatToFixed(e.value / withValue.find(el => el.stat === getPercentParam(e.stat))?.value * 100)) : null,
        }
      })
    })

    return {
      innerData,
      costFormatter,
      numberWithSpaces,
    }
  },
})
</script>

<style scoped lang="scss">
.widget-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 24px;
}

.info-widget {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  gap: 8px;
  border: 1px solid #D4D7DE;
  padding: 12px 16px;
  border-radius: 8px;
  flex: 1;
  cursor: pointer;
  color: #606266;
  background-color: white;

  .main-data {
    display: flex;
    flex-flow: column;
    gap: 8px;

    .value-wrapper {
      display: flex;
      flex-flow: wrap;
      gap: 8px;
    }

    .title {
      color: #A8ABB2;
      font-size: 13px;
      text-transform: uppercase;
      font-weight: 600;
    }

    .value {
      font-size: 20px;
      line-height: 24px;
    }

    .percent {
      color: #09993a;
      background-color: #e4fbee;
      font-size: 13px;
      padding: 4px 6px;
      border-radius: 4px;
    }
  }

  .period {
    font-size: 14px;
    font-weight: 600;
  }

  &._active,
  &:hover {
    border-color: #0681FC;

    .title {
      color: #0681FC;
    }
  }
}

@media (max-width: 980px) {
  .widget-wrapper {
    gap: 8px;
  }
}
</style>
