<template lang="pug">
.lot-card
  lot-link.lot-card__gov-ru-id(v-if="item.lotId" :id="item.lotId" :number="item.govRuId")
  .lot-card__status-title.lot-item__status-value._1(v-if="Number(item?.rankingPosition || '') === 1") ПОБЕДА
  .lot-card__status-title.lot-item__status-value._3(v-else-if="item?.isRejected") ОТКЛОНЕНО
  .lot-card__title {{ item.lotTitle }}
  analytic-string-block.lot-card__string-block(show-winner :item="item")
  .lot-card__participant-count
    .card-label Участники:
    .card-value {{ item.rankingPosition ?? "--" }} из {{ item.participantsCount }}
  .lot-card__start-cost
    .card-label Начальная цена:
    .card-value {{ costFormatter(item.cost.original.currency.alpha).format(item.cost.original.cost) }}
    .card-value(v-if="item?.cost?.converted?.currency?.alpha !== item?.cost?.original?.currency?.alpha")
      | {{ item.cost.converted && costFormatter(item.cost.converted.currency.alpha).format(item.cost.converted.cost) }}
  .lot-card__offered-price
    .card-label Предложенная цена:
    .card-value {{ costFormatter(item.offeredPrice.original.currency.alpha).format(item.offeredPrice.original.cost) }}
    .card-value(v-if="item?.offeredPrice?.converted?.currency?.alpha !== item?.offeredPrice?.original?.currency?.alpha")
      | {{ item.offeredPrice.converted && costFormatter(item.offeredPrice.converted.currency.alpha).format(item.offeredPrice.converted.cost) }}
    .card-value.lot-card__percentage {{ Number(item.reducePercentage).toFixed(2) }} %
  .lot-card__law
    .card-label Закон:
    .card-value {{ item.law?.title }}
  .lot-card__date-public
    .card-label Дата публикации:
    .card-value {{ formatDateDMY(item.datePublic) }}
  .lot-card__date-auction
    .card-label Дата итогов:
    .card-value {{ formatDateDMY(item.dateResult) }}
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { costFormatter } from "@/utils/formatter/costFormatter";
import { formatDateDMY } from "@/utils/formatter/dateFormatter";

import type { PropType } from "vue";
import type { AnalyseSuppliersParticipated } from "@/components/pages/analytics/table/cards/CardsDataInterfaces";

import LotLink from "@/components/ui/links/LotLink.vue";
import AnalyticStringBlock from "@/components/pages/analytics/table/cards/common/AnalyticStringBlock.vue";

export default defineComponent({
  name: "AnalyticsSuppliersParticipated",
  methods: {
    costFormatter,
    formatDateDMY,
  },
  components: {
    LotLink,
    AnalyticStringBlock,
  },
  props: {
    item: {
      type: Object as PropType<AnalyseSuppliersParticipated>,
      default: () => ({}),
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/styles/elements/tag";
@import "@/assets/styles/lots/analyticLotCard";

.lot-card {
  grid-template-areas:
    "gov-ru-id                  status-title                  status-title        status-title            status-title"
    "title                      title                         none                none                    none"
    "lot-card__string-block     lot-card__string-block        participant-count   start-cost              offered-price"
    "lot-card__string-block     lot-card__string-block        law                 date-public             date-auction";
  grid-template-rows: auto auto auto auto;
  grid-template-columns: 180px auto 100px 146px 146px;
}

@media (max-width: 1080px) {
  .lot-card {
    grid-template-areas:
    "gov-ru-id                 status-title             status-title            status-title     status-title"
    "title                     title                    title                   title            title"
    "lot-card__string-block    lot-card__string-block   participant-count       start-cost       offered-price"
    "lot-card__string-block    lot-card__string-block   law                     date-public      date-auction";
    grid-template-rows: auto auto auto auto;
    grid-template-columns: 180px auto 80px 146px 146px;
  }
}

@media (max-width: 960px) {
  .lot-card {
    grid-template-areas:
    "gov-ru-id                 status-title             status-title            status-title"
    "title                     title                    title                   title"
    "lot-card__string-block    lot-card__string-block   start-cost              offered-price"
    "lot-card__string-block    lot-card__string-block   participant-count       law"
    "lot-card__string-block    lot-card__string-block   date-public             date-auction";
    grid-template-rows: auto auto auto auto auto;
    grid-template-columns: 180px auto 146px 146px;
  }
}
</style>
